// banner style one



.rts-banner-one {
    position: relative;

    .animation-img {
        .shape-img {
            position: absolute;

            &.one {
                right: 3%;
                top: 34%;
                z-index: 1;
                animation: rotateIt2 25s linear infinite;
            }

            &.two {
                right: -24px;
                top: 77%;
                z-index: 1;
                animation: jump-2 3s infinite;
            }

            &.three {
                right: 13%;
                top: 21%;
                z-index: 1;
                animation: jump-1 3s infinite;
            }
        }
    }
}


.banner-one-inner {
    width: 50%;
    margin-left: auto;
    padding: 160px 0 190px 0;

    @media #{$smlg-device} {
        width: 70%;
    }

    position: relative;

    @media #{$smlg-device} {
        margin-left: inherit;
    }

    @media #{$sm-layout} {
        width: 90%;
    }

    .pre-title {
        color: #1C2539;
        text-transform: uppercase;
        font-size: 16px;
        margin-bottom: 10px;
        font-family: var(--font-secondary);
        letter-spacing: 1px;

        span {
            color: #DF0A0A;
            font-weight: 800;
        }
    }

    .title {
        font-weight: 700;
        line-height: 82px;
        margin-bottom: 23px;
        font-size: 60px;

        @media #{$md-layout} {
            line-height: 70px;
            margin-bottom: 23px;
        }

        @media #{$sm-layout} {
            font-size: 46px;
            line-height: 63px;
        }

        @media #{$large-mobile} {
            font-size: 32px;
            line-height: 45px;
        }

        @media #{$small-mobile} {
            font-size: 23px;
            line-height: 45px;
        }

        span {
            font-style: italic;
            color: #DF0A0A;
            font-weight: 300;
            font-size: 70px;

            @media #{$md-layout} {
                font-size: 56px;
            }

            @media #{$sm-layout} {
                font-size: 46px;
            }

            @media #{$large-mobile} {
                font-size: 32px;
            }
        }
    }

    p {
        color: #1C2539;
    }

    .rts-btn {
        display: block;
        margin-top: 43px;
        max-width: max-content;
    }
}



.banner-one {
    .swiper-slide-active {
        .pre-title {
            animation: fadeInUp 1.5s;
            animation-delay: 0s;
        }

        .title {
            animation: fadeInUp 1.5s;
            animation-delay: 0s;
        }

        p {
            &.disc {
                animation: fadeInUp 2s;
                animation-delay: 0s;
            }
        }

        a {
            &.rts-btn {
                animation: fadeInUp 2.5s;
                animation-delay: 0s;
            }
        }
    }
}

.rts-banner-area-two {
    .swiper-slide-active {
        .wrapper {
            .sub {
                animation: fadeInUp 1.5s;
                animation-delay: 0s;
                display: block;
            }

            .title {
                animation: fadeInUp 1.5s;
                animation-delay: 0s;
            }

            a {
                &.rts-btn {
                    animation: fadeInUp 2.5s;
                    animation-delay: 0s;
                }
            }
        }
    }
}

.banner-three {
    .swiper-slide-active {
        .banner-three-innerr {
            .subtitle-banner {
                animation: fadeInUp 1.5s;
                animation-delay: 0s;
                display: block;
            }

            .title {
                animation: fadeInUp 1.5s;
                animation-delay: 0s;
            }

            .disc {
                animation: fadeInUp 1.5s;
                animation-delay: 0s;
            }

            a {
                &.rts-btn {
                    animation: fadeInUp 2.5s;
                    animation-delay: 0s;
                }
            }
        }
    }

    &.banner-four {
        background: url(../images/banner/img.jpg);
        overflow: hidden;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        &::before {
            background: none;
        }

        .shape {
            position: absolute;
            right: 0;

            img {
                opacity: .7;
            }

            @media(max-width:1200px) {
                height: 100%;

                img {
                    height: 100%;
                }
            }

            @media(max-width:991px) {
                display: none;
            }

            &::after {
                position: absolute;
                content: '';
                background-image: url(../images/banner/shape/bg.png);
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                left: 0;
                top: 0;
                filter: drop-shadow(1);
                clip-path: polygon(37% -3%, 0% 155%, 100% 100%);

                @media(max-width:1200px) {
                    display: none;
                }
            }
        }

        .shape1 {
            position: absolute;
            top: -21%;
            left: -12%;
            z-index: 1;
            animation: fadeInLeftBig 2s;

            @media(max-width:1200px) {
                left: -12%;
                top: -7%;
                width: 35%;
            }
        }

        .shape2 {
            position: absolute;
            top: 28%;
            left: -5%;
            animation: fadeInLeftBig 3s;

            @media(max-width:1200px) {
                left: -7%;
                top: 22%;
            }

            @media(max-width:991px) {
                left: -10%;
                top: 10%;
                width: 35%;
            }
        }

        .shape3 {
            position: absolute;
            top: 70px;
            left: -20%;
            animation: fadeInLeftBig 3.5s;

            @media(max-width:1200px) {
                top: 0%;
                left: -35%;
            }

            @media(max-width:991px) {
                display: none;
            }
        }

        .shape4 {
            position: absolute;
            top: 0;
            left: -22%;
            animation: fadeInLeftBig 4s;

            @media(max-width:1200px) {
                display: none;
            }
        }

        .bg_banner-four {
            background: none;

            &::after {
                display: none;
            }

            .banner-four-inner {
                padding: 100px 0 100px 0;

                .subtitle-banner {
                    font-size: 16px;
                    line-height: 21px;
                    color: #1C2539;
                    font-weight: 400;
                }

                .title {
                    font-size: 60px;
                    font-weight: 700;
                    line-height: 70px;
                    text-transform: none;
                    color: #1C2539;
                    margin-bottom: 40px;
                    margin-top: 25px;

                    @media(max-width:576px) {
                        font-size: 44px;
                        line-height: 55px;
                    }

                    @media(max-width:576px) {
                        font-size: 34px;
                        line-height: 42px;
                    }
                }

                .disc {
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 28px;
                    color: #5D666F;
                    margin-bottom: 56px;

                    @media(max-width:991px) {
                        br {
                            display: none;
                        }
                    }
                }

                &::after {
                    display: none;
                }

                .button-group {
                    .btn-primary-4 {
                        &:hover {
                            background: var(--color-gray);
                            color: var(--color-primary-4);
                            border-color: var(--color-gray);
                        }

                        @media(max-width:576px) {
                            padding: 17px 30px;
                        }
                    }
                }
            }

            .hero-section {
                position: absolute;
                top: 0;
                right: 0;

                .shape {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }
    }
}


// banner three

.bg_banner-three {
    background-image: url(../images/banner/banner-04.jpg);
    // height: 100vh;
    position: relative;
    z-index: 1;
    width: 100%;

    &.slide-2 {
        background-image: url(../images/banner/banner-08.jpg);
    }

    &.slide-3 {
        background-image: url(../images/banner/banner-09.jpg);
    }

    &::after {
        position: absolute;
        height: 100%;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        background: #050a15b3;
        z-index: -1;
    }
}

.banner-three-inner {
    padding: 300px 0 100px 0;
    max-width: 100%;
    position: relative;
    z-index: 2;
    text-align: left;

    @media #{$sm-layout} {
        padding: 170px 0 100px 0;
    }

    @media #{$small-mobile} {
        padding: 126px 0 0 0;
    }

    .button-group {
        @media #{$small-mobile} {
            display: flex;
            flex-direction: column;
            max-width: max-content;

            .rts-btn {
                margin-bottom: 30px;
            }
        }
    }

    &::after {
        position: absolute;
        content: "Solution Model";
        left: 15%;
        line-height: 198px;
        width: 100%;
        top: 36%;
        font-size: 100px;
        font-weight: 800;
        -webkit-background-clip: text;
        -webkit-text-stroke: 2px transparent;
        // color: #291e1e;
        font-size: 150px;
        height: 128px;
        max-height: max-content;
        min-width: max-content;
        font-weight: 700;
        z-index: 0;
        display: flex;
        align-items: center;
        opacity: .25;
        font-family: var(--font-secondary);
        z-index: -1;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: .5px;
        -webkit-text-stroke-color: rgb(255, 255, 255);

        @media #{$laptop-device} {
            font-size: 100px;
        }

        @media #{$smlg-device} {
            font-size: 100px;
        }

        @media #{$md-layout} {
            font-size: 80px;
        }

        @media #{$sm-layout} {
            font-size: 60px;
            left: 0;
            top: 18%;
        }

        @media #{$large-mobile} {
            font-size: 40px;
        }

        @media #{$small-mobile} {
            top: 12%;
        }
    }

    .subtitle-banner {
        color: #fff;
        letter-spacing: 0.3em;
        text-transform: uppercase;

        @media #{$large-mobile} {
            letter-spacing: 0;
            font-size: 16px;
        }
    }

    .title {
        color: #fff;
        font-size: 100px;
        text-transform: uppercase;
        font-weight: 900;
        margin-top: 5px;
        margin-bottom: 17px;

        @media #{$smlg-device} {
            font-size: 75px;
        }

        @media #{$sm-layout} {
            font-size: 55px;
        }

        @media #{$large-mobile} {
            font-size: 40px;
        }

        @media #{$small-mobile} {
            font-size: 30px;
        }
    }

    .disc {
        color: #fff;
        width: 68%;
        margin-bottom: 46px;

        @media #{$sm-layout} {
            width: 100%;
        }

        @media #{$large-mobile} {
            width: 90%;
        }

        @media #{$small-mobile} {
            width: 99%;
            margin-bottom: 25px;
        }
    }

    .button-group {
        .rts-btn {
            margin-right: 25px;

            &.btn-primary-3 {
                border: 1px solid transparent;

                &.transparent {
                    border: 1px solid #fff;

                    &:hover {
                        background: var(--color-primary-3);
                        border: 1px solid transparent;
                    }
                }

                &:hover {
                    background: transparent;
                    border: 1px solid #FFFFFF;
                    color: #fff;
                }
            }
        }
    }

    &.banner-four-inner {
        .rts-btn {
            &.btn-primary-3 {
                border-radius: 30px;
                font-size: 16px;
                font-weight: 700;
                line-height: 21px;
                padding: 17px 50px;
            }
        }
    }
}



.banner-two {
    background-image: url(../images/banner/banner-05.png);
    height: 850px;
    background-repeat: no-repeat;
    position: relative;
    background-position: center;
    background-size: cover;
    width: 100%;

    @media #{$sm-layout} {
        height: 500px;
    }

    &.slide-2 {
        background-image: url(../images/banner/banner-06.png);
    }

    &.slide-3 {
        background-image: url(../images/banner/banner-07.png);
    }
}

.banner-two-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @media #{$sm-layout} {
        width: 100%;
    }

    .wrapper {
        span {
            &.sub {
                color: #fff;
                font-weight: 500;
                font-size: 22px;
                line-height: 29px;
            }
        }

        .title {
            font-weight: 300;
            font-size: 86px;
            line-height: 96px;
            color: #fff;
            margin-top: 24px;

            @media #{$laptop-device} {
                font-size: 59px;
                line-height: 73px;
            }

            @media #{$smlg-device} {
                font-size: 44px;
                line-height: 59px;
            }

            @media #{$large-mobile} {
                font-size: 30px;
                line-height: 40px;
            }

            span {
                font-weight: 700;
            }
        }

        a {
            &.rts-btn {
                background: #fff;
                border-radius: 15px;
                color: var(--color-primary-2);
                font-weight: 700;
                font-size: 16px;
                display: block;
                max-width: max-content;
                margin: 45px auto 0 auto;

                &:hover {
                    background: var(--color-primary-2);
                    color: #ffff;
                }
            }
        }
    }
}


.rts-banner-one {
    .swiper-wrapper {
        .swiper-slide {
            position: relative;

            &::after {
                position: absolute;
                content: '';
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: rgba(255, 255, 255, 0.336);
                display: none;

                @media #{$smlg-device} {
                    display: block;
                }
            }

            .banner-one-inner {
                position: relative;
                z-index: 1;

                .shape-img {
                    &.one {
                        position: absolute;
                        right: 40px;
                        bottom: 100px;
                        height: 436px;
                        width: 436px;
                        z-index: -1;
                        animation: rotateIt2 25s linear infinite;

                        @media #{$laptop-device} {
                            width: 300px;
                            height: 300px;
                        }

                        @media #{$smlg-device} {
                            width: 300px;
                            height: 300px;
                        }

                        @media #{$md-layout} {
                            width: 300px;
                            height: 300px;
                            right: -15%;
                        }

                        @media #{$sm-layout} {
                            width: 250px;
                            height: 250px;
                            right: -15%;
                        }

                        @media #{$large-mobile} {
                            height: 150px;
                            width: 150px;
                            right: 15%;
                        }
                    }
                }
            }
        }
    }
}


.banner-three {
    position: relative;

    &::before {
        position: absolute;
        content: "";
        background-image: url(../images/banner/shape/04.png);
        height: 100%;
        width: 650px;
        background-size: cover;
        right: 0;
        bottom: 0;
        z-index: 3;

        @media #{$md-layout} {
            width: 370px;
        }

        @media #{$sm-layout} {
            width: 350px;
        }

        @media #{$large-mobile} {
            width: 250px;
        }
    }
}

p {
    &.disc {
        &.banner-para {
            font-size: 18px;
            line-height: 28px;
            font-weight: 400;

            @media #{$large-mobile} {
                font-size: 16px;
                line-height: 26px;
            }
        }
    }
}


.col-xl-6.col-lg-6.col-md-6.col-sm-12.col-12.breadcrumb-1 {
    @media #{$sm-layout} {
        text-align: center;
    }
}


.rts-btn {
    &.color-h-black {
        &:hover {
            background: #1c2539 !important;
            color: #fff !important;
        }
    }
}

.header-two .main-header .content .header-right a.rts-btn {
    &:hover {
        color: #000;
    }
}