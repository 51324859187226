body {
    scroll-behavior: auto;
    background: #FFFFFF;
}
/*------------------------
    Header Sticky 
--------------------------*/

// custom column

.col-lg-20{
    width: 20%;
    float: left;
}

.col-xs-20,
.col-sm-20,
.col-md-20,
.col-lg-20 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
    padding-top: 15px;
}

.col-lg-20{
    @media (min-width: 1200px) {
        width: 20%;
        float: left;
    }
    @media #{$smlg-device} {
        width: 33%;
        float: left;
    }
    @media #{$md-layout} {
        width: 50%;
        float: left;
    }
    @media #{$sm-layout} {
        width: 50%;
        float: left;
    }
    @media #{$large-mobile} {
        width: 50%;
        float: left;
    }
    @media #{$small-mobile} {
        width: 100%;
        float: left;
    }
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #5D666F !important;
    opacity: 1 !important; /* Firefox */
    font-size: 16px;
}
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #5D666F  !important;
    font-size: 16px;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #5D666F !important;
    font-size: 16px;
  }


.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

// start hear

.bg-1{
    background: #e00a0a;
}
.bg-secondary{
    background: #1D2027!important;
}

.bg-white{
    background: #FFFFFF;
}

.bg-footer-one{
    background-repeat: no-repeat;
    background-size: cover;
    background: #0E1422;
    position: relative;
    overflow: hidden;
    z-index: 1;
    &::after{
        content: "";
        position: absolute;
        background-image: url(../images/footer/h1-shape/01.svg);
        height: 450px;
        width: 450px;
        right: -3%;
        background-repeat: no-repeat;
        bottom: -5%;
        @media #{$laptop-device} {   
            right: -15%;
        }
        @media #{$smlg-device} {   
            right: -15%;
        }
        @media #{$md-layout} {   
            left: 53%;
        }
    }
    &::before{
        content: "";
        position: absolute;
        background-image: url(../images/footer/h1-shape/02.png);
        height: 800px;
        width: 823px;
        left: -16%;
        background-position: center;
        bottom: -13%;
        background-size: cover;
        z-index: -1;
        background-repeat: no-repeat;
        @media #{$laptop-device} {
            left: -25%;
        }
        @media #{$smlg-device} {
            left: -25%;
        }
        @media #{$md-layout} {
            left: -48%;
        }
    }
    .bg-shape-f1{
        position: relative;
        z-index: 1;
        &::after{
            position: absolute;
            background-image: url(../images/footer/h1-shape/03.png);
            content: '';
            height: 500px;
            width: 523px;
            left: -25%;
            top: -20%;
            z-index: -1;
            background-repeat: no-repeat;
            @media #{$laptop-device} {
                left: -30%;
                top: -32%;
            }
            @media #{$smlg-device} {
                left: -30%;
                top: -32%;
            }
        }
    }
}


// title pre-title

.rts-title-area{
    position: relative;
    z-index: 1;
    &::after{
        position: absolute;
        content: "About Us";
        left: -14%;
        top: -39%;
        color: #ffffff;
        background-color: #000;
        background-size: 100% 100%;
        -webkit-background-clip: text;
        -webkit-text-stroke: 0.5px transparent;
        font-size: 150px;
        height: 100%;
        max-width: max-content;
        max-height: max-content;
        font-weight: 900;
        z-index: -1;
        display: flex;
        align-items: center;
        opacity: 0.35;
        font-family: var(--font-secondary);
        @media #{$smlg-device} {
            font-size: 90px;
        }
        @media #{$md-layout} {
            font-size: 90px;
        }
        @media #{$sm-layout} {
            font-size: 70px;
        }
        @media #{$small-mobile} {
            font-size: 50px;
        }
    }
    p.pre-title{
        color: var(--color-primary);
        margin-bottom: 0;
        font-family: var(--font-secondary);
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        margin-top: -5px;
        @media #{$small-mobile} {
            font-size: 14px;
        }
    }
    .title{
        margin-bottom: 24px;
    }
    &.service{
        &::after{
            content: 'Services';
            left: 50%;
            transform: translateX(-50%);
        }
    }
    &.pricing-planes{
        &::after{
            content: "price plans";
            left: 50%;
            transform: translateX(-50%);
            width: max-content;
            height: 168px;
            top: -70%;
        }
    }
    &.service-four{
        &::after{
            content: 'Services';
            left: 50%;
            transform: translateX(-50%);
            top: -20%;
        }
    }
    &.business{
        &::after{
            content: "Business Goal";
            min-width: max-content;
            min-height: 120px;
            left: 67%;
            transform: translateX(-50%);
            z-index: -1;
        }
    }
    &.gallery{
        &::after{
            content: "Gallery";
            left: 18%;
            transform: translateX(-50%);
            z-index: -1;
        }
    }
    &.team{
        &::after{
            content: "Members";
            left: 49%;
            transform: translateX(-50%);
            z-index: -1;
            height:109px;
        }
    }
    &.feedback{
        &::after{
            content: "Testimonial";
            left: 49%;
            transform: translateX(-50%);
            z-index: -1;
            height:109px;
        }
    }
    &.feature{
        &::after{
            content: "Features";
            left: 32%;
            transform: translateX(-50%);
            z-index: -1;
            height:109px;
            top: -18%;
            @media #{$laptop-device} {
                left: 55%;
                font-size: 110px;
            }
        }
    }
    &.reviews{
        &::after{
            content: "Reviews";
            left: 32%;
            transform: translateX(-50%);
            z-index: -1;
            height: 109px;
            top: 2%;        
        }
    }
    &.contact{
        &::after{
            content: "Hello";
            left: 52%;
            transform: translateX(-50%);
            z-index: -1;
            height: 109px;
            top: -41%;     
        }
    }
    &.contact-appoinment{
        &::after{
            position: absolute;
            content: "Hello";
            left: 18%;
            top: -39%;
            color: #8b868600;
            background-color: #241e1e00;
            background-size: 100% 100%;
            -webkit-background-clip: text;
            -webkit-text-stroke: 1px #585858;
            font-size: 150px;
            height: 100%;
            max-width: max-content;
            max-height: max-content;
            font-weight: 900;
            z-index: -1;
            display: flex;
            align-items: center;
            opacity: 0.35;
            font-family: var(--font-secondary);  
            @media #{$large-mobile} {
                font-size: 100px;
            }
        }
    }
    &.contact-fluid{
        &::after{
            content: "Contact Us";
            left: 49%;
            transform: translateX(-50%);
            z-index: -1;
            height: 109px;
            top: -40%;
            min-width: max-content;
        }
    }
}

.about-founder-wrapper{
    .author-inner{
        display: flex;
        align-items: center;
        position: relative;
        &::after{
            position: absolute;
            content: '';
            right: 11%;
            top: 50%;
            transform: translateY(-50%);
            height: 70px;
            width: 1px;
            background: #EBEBEB;
            @media #{$smlg-device} {
                display: none;
            }
        }
        .founder-details{
            a{
                .title{
                    margin-bottom: 1px;
                    transition: .3s;
                }
                &:hover{
                    .title{
                        color: var(--color-title);
                    }
                }
            }
            span{
                font-size: 14px;
                color: var(--color-primary);
                font-weight: 400;
            }
        }
        a{
            &.thumbnail{
                display: block;
                overflow: hidden;
                border-radius: 50%;
                margin-right: 16px;
                img{
                    transition: .3s;
                }
                &:hover{
                    img{
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
    .author-call-option{
        display: flex;
        align-items: center;
        img{
            padding: 12px;
            background: var(--color-primary);
            border-radius: 14px;
            margin-right: 16px;
        }
        span{
            color: #5D666F;
            font-size: 14px;        }
        .title{
            margin-bottom: 0;
            margin-top: 2px;
            transition: .3s;
            min-width: max-content;
            &:hover{
                color: var(--color-primary);
            }
        }
    }
}



// pagination
.pagination{
    margin: auto;
    max-width: max-content;
    margin-top: 25px;
    button{
        max-width: max-content;
        padding: 15px;
        border: 1px solid #EBEBEB;
        border-radius: 15px;
        margin-right: 10px;
        color: #1C2539;
        font-weight: 700;
        height: 50px;
        width: 50px;
        line-height: 18px;
        &.active{
            background: #e00a0a;
            color: #ffff;
        }
        &:hover{
            background: var(--color-primary);
            color: #fff;
        }
        &:last-child{
            margin-right: 0;
            font-size: 24px;
            i{
                margin-top: -3px;
            }
        }
    }
}


// blog grid style
.blog-grid-inner{
    border: 1px solid #E6E9F0;
    border-radius: 15px;
    .blog-header{
        position: relative;
        .blog-info{
            position: absolute;
            display: flex;
            align-items: center;
            padding: 7px 30px;
            bottom: 0;
            background: #1C2539;
            width: 100%;
            left: 0;
            @media #{$sm-layout} {
                padding-left: 15px;
            }
            .user{
                margin-right: 25px;
                i{
                    color: #fff;
                }
                span{
                    color: #fff;
                    font-size: 16px;
                    font-weight: 400;
                    margin-left: 8px;
                }
            }
        }
        .date{
            position: absolute;
            padding: 10px 20px;
            border-radius: 15px;
            right: 20px;
            bottom: 20px;
            background: #fff;
            .title{
                margin-bottom: 0;
                color: #e00a0a;
                margin-bottom: -5px;
            }
            span{
                color: #e00a0a;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
    .thumbnail{
        overflow: hidden;
        border-radius: 15px 15px 0 0;
        width: 100%;
        display: block;
        &:hover{
            img{
                transform: scale(1.2);
            }
        }
        img{
            width: 100%;
            transition: .3s;
        }
    }
    .blog-body{
        padding: 30px;
        @media #{$sm-layout} {
            padding: 15px;
        }
        a{
            .title{
                margin-bottom: 0;
                transition: .3s;
                line-height: 32px;
            }
            &:hover{
                .title{
                    color: var(--color-primary);
                }
            }
        }
    }
}



.social-three-wrapper{
    display: flex;
    align-items: center;
    padding-left: 0;
    li{
        list-style: none;
        margin: 0 21px;
        &:first-child{
            margin-left: 16px;
        }
        a{
            position: relative;
            z-index: 1;
            transition: .3s;
            color: #1C2539;
            &::after{
                position: absolute;
                content: '';
                background: #E8E8E8;
                height: 40px;
                width: 40px;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                border-radius: 5px;
                z-index: -1;
                transition: .3s;
            }
            &:hover{
                color: #fff;
                &::after{
                    background: var(--color-primary-3);
                }
            }
        }
    }
}

.over_link{
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9;
    left: 0;
    top: 0;
}

.container-2{
    max-width: 1680px;
    margin: auto;
}




// Home Blue area
.home-blue{
    .title-area{
        position: relative;
        z-index: 1;
        &::after{
            position: absolute;
            content: "00";
            left: 50%;
            transform: translateX(-50%);
            top: -39%;
            color: #ffffff;
            background-color: #000;
            background-size: 100% 100%;
            -webkit-background-clip: text;
            -webkit-text-stroke: 0.5px transparent;
            font-size: 143px;
            height: 100%;
            max-width: max-content;
            max-height: max-content;
            font-weight: 900;
            z-index: -1;
            display: flex;
            align-items: center;
            opacity: 0.35;
            font-family: var(--font-secondary);
            z-index: -1;
        }
        &.blog{
            &::after{
                left: 50%;
                transform: translateX(-50%);
                top: -39%;
                content: "08";
            }
        }
        &.about-company{
            &::after{
                left: 7%;
                transform: translateX(-50%);
                top: -42%;
                content: "02";
                @media #{$sm-layout} {
                    left: 18%;
                    transform: translateX(-50%);
                    top: -27%;
                }
            }
        }
        &.service-h2{
            &::after{
                left: 7%;
                transform: translateX(-50%);
                top: -42%;
                content: "01";
            }
        }
        &.business-case{
            &::after{
                left: 50%;
                transform: translateX(-50%);
                top: -25%;
                content: "05";
            }
        }
        &.working-process{
            &::after{
                left: 51%;
                transform: translateX(-50%);
                top: -42%;
                content: "03";
            }
        }
        &.service-h2{
            &::after{
                left: 3%;
                transform: translateX(-50%);
                top: -42%;
                content: "01";
                @media #{$sm-layout} {
                    left: 18%;
                }
            }
        }
        &.service-h2{
            &.service{
                &::after{
                    left: 3%;
                    transform: translateX(-50%);
                    top: -42%;
                    content: "04";
                    @media #{$sm-layout} {
                        left: 18%;
                    }
                }
            }
        }
        &.testimonial-h2{
            &::after{
                left: 4%;
                transform: translateX(-50%);
                top: -38%;
                content: "06";
                color: #121212;
                background-color: #ffffff6b;
            }
        }
        &.pricing-h2{
            &::after{
                left: 50%;
                transform: translateX(-50%);
                top: -40%;
                content: "07";
            }
        }
        span{
            font-size: 18px;
            color: #5D666F !important;
            font-weight: 500 !important;
        }
        .title{
            font-size: 48px;
            color: #1C2539 !important;
            font-weight: 700;
            line-height: 58px;
        }
    }
}

.home-blue{
    .single-feature-one i{
        background: var(--color-primary-2);
    }
}