

// services stye hear

.background-service{
    background-image: url(../images/service/bg-01.jpg);
    padding: 70px;
    background-repeat: no-repeat;
    background-size: cover;
    @media #{$large-mobile} {
        padding: 20px;
    }
    &.service-three{
        background-image: url(../images/service/bg-04.jpg);
        padding: 70px 195px;
        @media #{$laptop-device} {
            padding: 70px 100px;
        }
        @media #{$smlg-device} {
            padding: 70px 100px;
        }
        @media #{$md-layout} {
            padding: 70px 50px;
        }
        @media #{$sm-layout} {
            padding: 40px 0px 60px 20px;
        }
        @media #{$large-mobile} {
            padding: 0px 0px 40px 20px;
        }
    }
}

.plr--120-service{
    padding: 0 120px;
    @media #{$smlg-device} {
        padding: 0;
    }
}


.service-one-inner{
    display: flex;
    padding: 52px 35px 40px 64px;
    position: relative;
    height: 100%;
    @media #{$laptop-device} {
        padding: 36px 19px 40px 23px;
    }
    @media #{$md-layout} {
        padding: 42px 18px 25px 15px;
    }
    @media #{$sm-layout} {
        padding: 42px 18px 25px 15px;
    }
    &::after{
        @media #{$smlg-device} {
            display: none;
        }
    }
    &::before{
        @media #{$smlg-device} {
            display: none;
        }
    }
    &::after{
        position: absolute;
        content: '';
        height: 100%;
        width: 1px;
        background: #0001;
        right: -15px;
    }
    &.one{
        &::before{
            position: absolute;
            content: "";
            width: 90%;
            height: 1px;
            background: #0001;
            bottom: 0;
            left: 13%;
        }
    }
    &.two{
        &::before{
            position: absolute;
            content: '';
            width: 103%;
            height: 1px;
            background: #0001;
            bottom: 0;
            left: 0;
        }
    }
    &.three{
        &::before{
            position: absolute;
            content: '';
            width: 103%;
            height: 1px;
            background: #0001;
            bottom: 0;
            left: 0;
        }
        &::after{
            display: none;
        }
    }
    &.four{
        &::after{
            position: absolute;
            content: '';
            height: 71%;
            width: 1px;
            background: #0001;
            right: -15px;
        }
    }
    &.five{
        &::after{
            position: absolute;
            content: '';
            height: 71%;
            width: 1px;
            background: #0001;
            right: -15px;
        }
    }
    &.six{
        &::after{
            position: absolute;
            content: '';
            height: 71%;
            width: 1px;
            background: #0001;
            right: -15px;
            display: none;
        }
    }
    .thumbnail{
        margin-right: 37px;
        @media #{$extra-device} {
            margin-right: 20px;
        }
        @media #{$laptop-device} {
            margin-right: 20px;
        }
        @media #{$smlg-device} {
            margin-right: 20px;
        }
        img{
            max-width: 65px;
            height: auto;
            @media #{$laptop-device} {
                max-width: 50px;
            }
            @media #{$smlg-device} {
                max-width: 40px;
            }
        }
    }
    .service-details{
        .title{
            margin-bottom: 7px;
            @media #{$extra-device} {
                font-size: 21px;
            }
            @media #{$laptop-device} {
                font-size: 20px;
            }
        }
        p{
            margin-bottom: 20px;
        }
    }
}

.color-primary{
    color: var(--color-primary);
    &.sub{
        font-weight: 600;
        letter-spacing: 0.1em;
        @media #{$small-mobile} {
            font-size: 14px;
        }
    }
}

// service details
.service-detials-step-2{
    p{
        &.disc{
            font-size: 16px;
            line-height: 26px;
        }
    }
}

.service-detials-step-1{
    .thumbnail{
        margin-bottom: 45px;
    }
    .title{
        margin-bottom: 16px;
    }
    p{
        &.disc{
            margin-bottom: 23px;
            font-size: 16px;
            line-height: 26px;
        }
    }
    .service-details-card{
        padding: 30px;
        box-shadow: 0px 8px 30px #6a6a6a1a;
        display: flex;
        align-items: flex-start;
        border-left: 5px solid var(--color-primary);
        .thumbnail{
            img{
                height: 29px;
                width: 74px;
            }
        }
        .details{
            margin-left: 20px;
            .title{
                margin-bottom: 5px;
                font-size: 20px;
            }
            p{
                margin-bottom: 0;
            }
        }
    }
}
.service-detials-step-2{
    // single step
    .single-service-step{
        background: #fff;
        padding: 40px 20px;
        border: 1px solid #EEEEEE;
        box-shadow: 0px 6px 39px #0000000a;
        p{
            &.step{
                position: relative;
                color: #fff;
                z-index: 1;
                margin-bottom: 52px;
                font-weight: 700;
                font-size: 20px;
                line-height: 28px;
                margin-top: 29px;
                &::after{
                    position: absolute;
                    content: '';
                    left: 50%;
                    top: 50%;
                    background: #DF0A0A;
                    height: 60px;
                    width:60px;
                    transform: translate(-50% , -50%);
                    z-index: -1;
                    border-radius: 50%;
                }
                &::before{
                    position: absolute;
                    content: '';
                    left: 50%;
                    top: 50%;
                    background: #e81c2e1a;
                    height: 90px;
                    width:90px;
                    transform: translate(-50% , -50%);
                    z-index: -1;
                    border-radius: 50%;
                }
            }
        }
        .title{
            margin-bottom: 8px;
        }
        p{
            &.disc{
                font-size: 16px;
                line-height: 26px;
            }
        }
    }
}

.service-detials-step-3{
    .title{
        margin-bottom: 8px;
    }
    p{
        &.disc{
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 20px;
        }
    }
    .single-banifits{
        margin-bottom: 10px;
        i{
            margin-right: 15px;
            color: var(--color-primary);
        }
    }
}


// service page style hear

.service-two-bg{
    background-image: url(../images/service/bg-02.jpg);
}

.service-two-inner{
    box-shadow: 0px 13px 40px #1810100a;
    position: relative;
    border-radius: 15px;
    .thumbnail{
        overflow: hidden;
        display: block;
        border-radius: 15px 15px 0 0 ;
        position: relative;
        &::after{
            position: absolute;
            right: 0;
            top: 0;
            content: '01';
            font-size: 18px;
            background: #fff;
            border-radius: 0 0 0 15px;
            height: 50px;
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--color-primary);
            font-weight: 700;
        }
        &.two{
            &::after{
                content: '02';
            }
        }
        &.three{
            &::after{
                content: '03';
            }
        }
        &.four{
            &::after{
                content: '04';
            }
        }
        &.five{
            &::after{
                content: '05';
            }
        }
        &.six{
            &::after{
                content: '06';
            }
        }
        &.seven{
            &::after{
                content: '07';
            }
        }
        &.eight{
            &::after{
                content: '08';
            }
        }
        &.nine{
            &::after{
                content: '09';
            }
        }
        img{
            width: 100%;
            border-radius: 15px 15px 0 0;
            transition: .3s;
        }
    }
    .body-content{
        z-index: 5;
        padding: 40px;
        background: #fff;
        border-radius: 0 0 15px 15px;
        position: absolute;
        bottom: -50%;
        @media #{$sm-layout} {
            bottom: -37%;
        }
        @media #{$small-mobile} {
            padding: 20px;
        }
        .hidden-area{
            max-height: 80px;
            overflow:hidden ;
            transition: .5s ease-in-out;
        }
        .title{
            margin-bottom: 8px;
            font-size: 22px;
        }
        p{
            font-size: 16px;
            line-height: 26px;
            font-weight: 400;
            color: #5D666F;
            margin-bottom: 15px;
        }
        .rts-read-more-two{
            color: var(--color-primary);
            font-weight: 700;
            font-size: 16px;
            align-items: center;
            display: flex;
            i{
                margin-left: 5px;
                transition: .3s;
            }
            &:hover{
                i{
                    margin-left: 8px;
                }
            }
        }
    }
    &:hover{
        .thumbnail{
            img{
                transform: scale(1.15);
            }
        }
        .body-content{
            .hidden-area{
                max-height: 150px;
                bottom: 0;
            }
        }
    }
}


.col-xl-4.col-md-6.col-sm-12.col-12.pb--140.pb_md--100,
.col-xl-4.col-md-6.col-sm-12.col-12.pb--140.pb_md--60{
    @media #{$small-mobile} {
        padding-bottom: 80px !important;
    }
}
.col-xl-4.col-md-6.col-sm-12.col-12.pb--140.pb_md--60.pb_sm--60{
    @media #{$small-mobile} {
        padding-bottom: 30px !important;
    }
}
.padding-controler{
    margin-bottom: -75px;
}

// accordion service area
.accordion-service-bg{
    background-image: url(../images/service/bg-03.jpg);
    @media #{$smlg-device} {
        background-image: none;
        background: #F6F6F6;
    }
}


.rts-accordion-area{
    &.service{
        padding-left: 120px;
        padding-right: 120px;
        @media #{$smlg-device} {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.accordion-service-inner{
    .accordion-area{
        padding-right: 130px;
        @media #{$large-mobile} {
            padding-right: 15px;
        }
        .accordion{
            margin-top: 45px;
            @media #{$large-mobile} {
                margin-left: 0;
                margin-top: 30px;
            }
            .accordion-item{
                background: transparent;
                border: none;
                padding-bottom: 30px;
                position: relative;
                padding-left: 60px;
                &:last-child{
                    padding-bottom: 0;
                }
                @media #{$smlg-device} {
                    padding-left: 98px;
                }
                @media #{$sm-layout} {
                    padding-left: 52px;
                }
                @media #{$large-mobile} {
                    padding-left: 0;
                    padding-bottom: 15px;
                }
                @media #{$small-mobile} {
                    padding-left: 0;
                }
                &:last-child{
                    &::after{
                        display: none;
                    }
                }
                &::after{
                    content: "";
                    display: block;
                    width: 1px;
                    height: 100%;
                    border: 1px dashed #DF0A0A;
                    top: 3px;
                    left: 18px;
                    position: absolute;
                    @media #{$large-mobile} {
                        display: none;
                    }
                }
                .accordion-header{
                    @media #{$sm-layout} {
                        margin-bottom: 10px;
                    }
                    button{
                        background: transparent;
                        border: none;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 23px;
                        box-shadow: none;
                        padding: 0;
                        position: relative;
                        color: #1C2539;
                        &[aria-expanded="true"]{
                            @media #{$sm-layout} {
                                color: var(--color-primary);
                            }
                        }
                        &::before{
                            content: "";
                            position: absolute;
                            left: 0;
                            background-image:url(../images/service/icon/dot.png);
                            height: 20px;
                            width: 20px;
                            left: -11.5%;
                            background-repeat: no-repeat;
                            background-size: cover;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            line-height: 30px;
                            background-color: #fff;
                            z-index: 5;
                            transition: .3s;
                            @media #{$laptop-device} {
                                left: -51px;
                            }
                            @media #{$smlg-device} {
                                left: -12.5%;
                            }
                            @media #{$md-layout} {
                                left: -18.5%;
                            }
                            @media #{$large-mobile} {
                                display: none;
                            }
                        }
                        &[aria-expanded="true"]{
                            &::before{
                                content: "";
                                position: absolute;
                                left: 0;
                                background-image:none;
                                height: 50px;
                                width: 50px;
                                left: -14.6%;
                                background: var(--color-primary);
                                background-repeat: no-repeat;
                                background-size: cover;
                                border-radius: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                line-height: 30px;
                                z-index: 5;
                                @media #{$laptop-device} {
                                    left: -66px;
                                }
                                @media #{$md-layout} {
                                    left: -21.6%;
                                }
                                @media #{$sm-layout} {
                                    left: -17.6%;
                                }
                                @media #{$large-mobile} {
                                    display: none;
                                }
                            }
                        }
                        &:focus{
                            box-shadow: none;
                        }
                        &::after{
                            display: none;
                        }
                    }
                }
                .accordion-body{
                    padding: 0;
                }
            }
        }
    }
}


.service-one-inner-four{
    position: relative;
    a{
        &.rts-btn{
            display: block;
            max-width: max-content;
            position: absolute;
            bottom: -57px;
            left: 40px;
            border-radius: 0 0 15px 15px;
            border: 1px solid transparent;
            display: flex;
            align-items: center;
            @media #{$sm-layout} {
                bottom: -10%;
            }
            @media #{$small-mobile} {
                bottom: -15%;
            }
            @media screen and (max-width: 380px) {
                bottom: -21%;
            }
            i{
                margin-left: 5px;
            }
            &:hover{
                border: 1px solid var(--color-primary);
                background: #fff;
            }
        }
    }
    .big-thumbnail-area{
        position: relative;
        z-index: 1;
        cursor: pointer;
        .thumbnail{
            overflow: hidden;
            display: block;
            border-radius: 15px;
            &:hover{
                img{
                    transform: scale(1.2);
                }
            }
            img{
                width: 100%;
                transition: .5s;
                border-radius: 15px;
            }
        }
        .content{
            position: absolute;
            bottom: 40px;
            left: 40px;
            right: 40px;
            z-index: 1;
            height: 140px;
            overflow: hidden;
            transition: .5s;
            text-align: left;
            img{
                max-width: 85px;
                height: auto;
                margin-bottom: 30px;
            }
            .title{
                color: #fff;
                margin-bottom: 10px;
            }
            p{
                &.disc{
                    color: #fff;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 26px;
                }
            }
        }
        .over_link{
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 10;
            top: 0;
            left: 0;
            border-radius: 15px;
        }
        img{
            width: 100%;
        }
        &::after{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 85%;
            display: block;
            z-index: 0;
            content: "";
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 24.52%, rgba(6, 9, 12, 0.85) 87.86%);
            transition: opacity 0.65s cubic-bezier(0.05, 0.2, 0.1, 1);
            cursor: pointer;
            border-radius: 15px;
            transition: .5s;
        }
        &:hover{
            &::after{
                height: 100%;
                background: linear-gradient(to bottom, rgb(15 15 15 / 1%), rgb(0 0 0 / 100%) 100%);
            }
            .content{
                height: 206px;
            }
        }
    }
    &:hover{
        .thumbnail{
            img{
                transform: scale(1.2);
            }
        }
    }
}

.title-service-three{
    p{
        color: var(--color-primary-3);
        margin-bottom: 0;
    }
    .title{
        margin-top: 10px;
        text-transform: uppercase;
    }
}


.bg-service-h2{
    background-image: url(../images/service/bg-02.jpg);
    margin-top: -200px;
    padding-top: 320px;
    background-size: cover;
    background-repeat: no-repeat;
    @media #{$sm-layout} {
        padding-top: 294px;
    }
}

.rts-single-service-h2{
    padding: 25px;
    border-radius: 20px;
    background: #fff;
    border: 1px solid #E4E7EE;
    transition: .3s;
    .thumbnail{
        overflow: hidden;
        display: block;
        border-radius: 20px;
        img{
            width: 100%;
            transition: .4s;
        }
    }
    .body{
        margin-top: 22px;
        a{
            &:hover{
                .title{
                    color: var(--color-primary-2);
                }
            }
        }
        .title{
            margin-bottom: 10px;
            transition: .3s;
            font-size: 22px;
        }
        p{
            &.disc{
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                margin-bottom: 12px;
            }
        }
        a{
            &.btn-red-more{
                color: #1C2539;
                font-weight: 700;
                display: flex;
                align-items: center;
                i{
                    margin-left: 0;
                    opacity: 0;
                    transition: .3s;
                }
            }
        }
    }
    &:hover{
        box-shadow: 0px 12px 24px rgba(18, 22, 34, 0.07);
        .thumbnail{
            img{
                transform: scale(1.2);
            }
        }
        .body{
            a{
                &.btn-red-more{
                    color: var(--color-primary-2);
                    i{
                        margin-left: 7px;
                        opacity: 1;
                    }
                }
            }
        }
    }   
}


.navigation-center-bottom{
    &.service{
        .swiper-button-next{
            @media #{$md-layout} {
                right: 40%;
            }
            @media #{$sm-layout} {
                right: 40%;
            }
            @media #{$large-mobile} {
                right: 36%;
            }
        }
        .swiper-button-prev{
            @media #{$md-layout} {
                left: 40%;
            }
            @media #{$sm-layout} {
                left: 40%;
            }
            @media #{$large-mobile} {
                left: 36%;
            }
        }
    }
    display: flex;
    align-items: center;
    .swiper-pagination{
        max-width: fit-content;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;
        bottom: -23px;
        font-weight: 700;
        font-size: 16px;
        color: #1C2539;
        .swiper-pagination-current{
            color: var(--color-primary-3);
        }
    }
    .swiper-button-next{
        right: 45%;
        &::after{
            content: "\f061";
            font-family: "Font Awesome 5 Pro";
            font-size: 16px;
            color: #1C2539;
            padding: 10px;
            background: #fff;
            box-shadow: 0px 10px 20px rgb(24 16 16 / 6%);
            border-radius: 8px;
            transition: 0.3s;
        }
        &:hover{
            &::after{
                background: var(--color-primary-3);
                color: #fff;
            }
        }
    }
    .swiper-button-prev{
        left: 45%;
        &::after{
            content: "\f060";
            font-family: "Font Awesome 5 Pro";
            font-size: 16px;
            color: #1C2539;
            padding: 10px;
            background: #fff;
            box-shadow: 0px 10px 20px #1810100f;
            border-radius: 8px;
            transition: 0.3s;
        }
        &:hover{
            &::after{
                background: var(--color-primary-3);
                color: #fff;
            }
        }
    }
}



.thumbnail{
    &.sm-thumb-service{
        img{
            @media #{$md-layout} {
                width: 100%;
            }
            @media #{$sm-layout} {
                width: 100%;
            }
        }
    }
}

.about-service-width-controler{
    max-width: 87.5%;
    @media #{$md-layout} {
        max-width: 100%;
    }
    @media #{$small-mobile} {
        max-width: 100%;
        margin-right: -7px;
    }
}

.rts-service-area4{
    .container{
        .title-service-three{
            .pre-title{
                color: var(--color-primary-4);
                font-size: 16px;
                font-weight: 600;
                line-height: 21px;
                text-transform: uppercase;
                letter-spacing: 0.1em;
                font-family: "Red Hat Display", sans-serif;
            }
            .title{
                text-transform: none;
                @media(max-width:767px){
                    margin-bottom: 0;
                }
            }
        }
        .service-one-inner-four{
            @media(max-width:991px){
                margin-bottom: 60px;
            }
            .big-thumbnail-area{
                background: #f7f7f7;
                border-radius: 15px;
                padding: 40px;
                &::after{
                    display: none;
                }
                @media(max-width:991px){
                    padding: 30px;
                }
                
                .content{
                    position: unset;
                    height: auto;
                    margin-top: 25px;
                    .title{
                        color: #1C2539;
                        font-size: 24px;
                        line-height: 32px;
                        font-weight: 700;
                        font-family: "Red Hat Display", sans-serif;
                    }
                    .disc{
                        color: #5D666F;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 26px;
                    }
                }
            }
            .btn-primary-3{
                background: var(--color-primary-4);
                bottom: -55px;
                border: none;
                &:hover{
                    background: #E4E7FF;
                    color: var(--color-primary-4);
                }
            }
        }
    }
}



