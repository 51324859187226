
// business goal style hear

.business-goal-one{
    position: relative;
    img{
        &.small{
            position: absolute;
            top: 9%;
            transform: translateY(-50%);
            right: 2%;
            animation: jump-2 5s linear infinite;
            z-index: 7;
            @media #{$large-mobile} {
                width: 50%;
                top: 37%;
            }
        }
    }
}

.rts-business-goal{
    margin-top: 50px;
    .single-goal{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 30px;
        &:last-child{
            margin-bottom: 0;
        }
        img{
            margin-right: 30px;
        }
        .goal-wrapper{
            .title{
                margin-bottom: 5px;
            }
            p{
                color: #5D666F;
            }
        }
    }
}

.goal-button-wrapper{
    display: flex;
    align-items: center;
    @media #{$large-mobile} {
        align-items: flex-start;
        flex-direction: column;
    }
    .vedio-icone{
        margin-left: 70px;
        @media #{$md-layout} {
            margin-left: 136px;
        }
        @media #{$large-mobile} {
            margin-left: 29px;
            margin-top: 50px;
        }
    }
}

.rts-business-solution-left{
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-bottom: 80px;
    .success-rate{
        position: absolute;
        background: #F64A00;
        padding: 47px;
        left: -1%;
        bottom: -13%;
        width: 254px;
        height: 167px;
        animation: jump-1 5s linear infinite;
        @media #{$sm-layout} {
            width: inherit;
            height: inherit;
            padding: 22px;
            bottom: -10%;
        }
        .title{
            margin-bottom: 0;
            color: #fff;
        }
        span{
            font-size: 16px;
            letter-spacing: 0.16em;
            color: #fff;
            font-weight: 500;
        }
    }
}


.rts-business-solution-right{
    padding-left: 50px;
    @media #{$sm-layout} {
        padding-left: 0;
    }
    .title-area{
        .sub{
            color: var(--color-primary-3);
            font-weight: 700;
            letter-spacing: 0.3em;
            text-transform: uppercase;
        }
        .title{
            font-size: 60px;
            line-height: 65px;
            text-transform: uppercase;
            @media #{$laptop-device} {
                font-size: 50px;
            }
            @media #{$smlg-device} {
                font-size: 36px;
                line-height: 50px;
            }
            @media #{$sm-layout} {
                font-size: 34px;
                line-height: 45px;
            }
            @media #{$large-mobile} {
                font-size: 24px;
                line-height: 40px;
            }
            span{
                font-size: 48px;
                @media #{$smlg-device} {
                    font-size: 30px;
                }
                @media #{$sm-layout} {
                    font-size: 34px;
                }
                @media #{$sm-layout} {
                    font-size: 24px;
                }
                span{
                    color: var(--color-primary-3);
                }
            }
        }
    }
    .content-area{
        p{
            &.disc{
                font-size: 16px;
                line-height: 26px;
                font-weight: 400;
                color: #5D666F;
            }
        }
    }
}

.single-business-solution-2{
    margin-left: 38px;
    background: #FFFFFF;
    box-shadow: 0px 2px 20px rgba(24, 16, 16, 0.07);
    padding: 32px;
    position: relative;
    border-left: 8px solid transparent;
    transition: .3s;
    margin-bottom: 25px;
    @media #{$small-mobile} {
        padding: 15px 10px;
    }
    &:hover{
        transform: translateY(-5px);
    }
    &.active{
        border-left: 8px solid var(--color-primary-3);
    }
    &:last-child{
        margin-bottom: 0;
    }
    .icon{
        position: absolute;
        left: -45px;
        top: 50%;
        transform: translateY(-50%);
    }
    .content{
        padding-left: 30px;
        @media #{$small-mobile} {
            padding-left: 45px;
        }
    }
    .title{
        font-size: 22px;
        margin-bottom: 5px;
        @media #{$small-mobile} {
            font-size: 16px;
        }
    }
    p{
        &.disc{
            font-size: 16px;
            line-height: 26px;
            color: #5D666F; 
        }
    }
    &:hover{
        border-color: var(--color-primary-3);
    }
}

.business-case-bg-2{
    background-image: url(../images/business-case/bg-02.jpg);
    background-position: top center;
    background-repeat: no-repeat;
    width: 100%;
}

.container-cusiness-case-h2{
    max-width: 1680px;
    margin: 0 auto;
}
.rts-business-case-s-2{
    position: relative;
    overflow: hidden;
    z-index: 1;
    border-radius: 20px;
    width: 100%;
    &::after{
        content: "";
        position: absolute;
        background-image: url(../images/business-case/icon/shape-1.png);
        height: 100%;
        width: 100%;
        top: -64%;
        right: -56%;
        z-index: 0;
        background-repeat: no-repeat;
        transition: .3s;
    }
    .thumbnail{
        border-radius: 20px;
        overflow: hidden;
        img{
            overflow: hidden;
            transition: .3s;
            border-radius: 20px;
        }
    }
    .inner{
        padding: 35px;
        text-align: center;
        position: absolute;
        bottom: 35px;
        left: 50%;
        transform: translateX(-50%);
        width: 84%;
        background: #fff;
        border-radius: 20px;
        overflow: hidden;
        z-index: 10;
        @media #{$md-layout} {
            padding: 20px;
        }
        @media #{$sm-layout} {
            padding: 15px;
        }
        a{
            &:hover{
                .title{
                    color: var(--color-primary-2);
                }
            }

        }
        span{
            color: #5D666F;
            font-size: 16px;            
            position: relative;
            &::after{
                position: absolute;
                content: "";
                background-image: url(../images/business-case/icon/02.png);
                height: 58px;
                width: 58px;
                bottom: -85px;
                left: -144px;
                transition: 0.3s;
            }
        }
        .title{
            margin-bottom: 2px;
            transition: .3s;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: #1C2539;
            @media #{$md-layout} {
                font-size: 20px;
            }
            @media #{$sm-layout} {
                font-size: 18px;
                margin-bottom: -4px;
            }
        }
    }
    &:hover{
        .thumbnail{
            img{
                transform: scale(1.1);
                filter: grayscale(1);
            }
        }
        &::after{
            top: -16%;
            right: -56%;
        }
        .inner{
            span{
                &::after{
                    bottom: -55px;
                    left: -111px;
                }
            }
        }
    }
}

.rts-business-goal2{
    padding-top: 120px;
    @media(max-width:767px){
        padding-top: 60px;
    }
    .container{
        .business-goal-right{
            .rts-title-area{
                &::after{
                    display: none;
                }
                .pre-title{
                    color: var(--color-primary-4);
                }
            }
            .single-goal{
                .goal-wrapper{
                    .title{
                        color: #0B101C;
                        font-family: "Red Hat Display", sans-serif;
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 26px;
                    }
                }
            }
            .goal-button-wrapper{
                .btn-primary{
                    background: var(--color-primary-4);
                    &:hover{
                        background: #E4E7FF !important;
                        color: var(--color-primary-4) !important;
                    }
                }
                .vedio-icone{
                    .video-play-button{
                        span{
                            border-left-color: var(--color-primary-4);
                        }
                        &::before{
                            border-color: #E4E7FF;
                        }
                        &::after{
                            background: #E4E7FF;
                        }
                        .outer-text{
                            color: var(--color-primary-4);
                        }
                    }
                }
            }
        }
    }
}



