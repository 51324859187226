// about area start


.about-inner{
    p{
        &.disc{
            margin-bottom: 30px;
        }
    }
}


.bg-about-sm-shape{
    position: relative;
    &::after{
        content: '';
        position: absolute;
        height: 150px;
        width: 150px;
        background-image: url(../images/about/shape/01.png);
        background-repeat: no-repeat;
        top: 32%;
        animation: jump-1 8s linear infinite;
        @media #{$sm-layout} {
            right: -15%;
        }
        @media #{$small-mobile} {
            right: -32%;
        }
    }
    &::before{
        content: '';
        position: absolute;
        height: 150px;
        width: 150px;
        background-image: url(../images/about/shape/02.png);
        background-repeat: no-repeat;
        top: 60%;
        right: -2%;
        animation: rotateIt 10s linear infinite;
        z-index: -1;
        @media #{$md-layout} {
            top: 76%;
        }
        @media #{$sm-layout} {
            top: 76%;
            height: 100px;
            width: 100px;
        }
    }
}


.about-success-wrapper{
    .single{
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        align-items: flex-start;
        i{
            padding: 4px;
            background: var(--color-primary);
            border-radius: 50%;
            color: #fff;
            font-size: 12px;
            margin-right: 15px;
            margin-top: 5px;
        }
        p{
            &.details{
                font-size: 16px;
                color: #1C2539;
                font-weight: 500;
            }
        }
    }
}

.about-one-thumbnail{
    display: flex;
    justify-content: end;
    position: relative;
    .small-img{
        position: absolute;
        left: 0%;
        top: 7%;
        animation: jump-1 10s linear infinite;
    }
    .experience{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 25px 32px;
        background: var(--color-primary);
        border-radius: 15px;
        bottom: 7%;
        left: 1%;
        animation: jump-2 6s linear infinite;
        width: 310px;
        .single{
            width: 50%;
        }
        .left{
            .title{
                color: #fff;
                margin-bottom: 0;
                font-family: var(--font-secondary);
            }
            p{
                font-size: 22px;
                color: #fff;
                font-family: var(--font-secondary);
                font-weight: 700;
                margin-top: -8px;
            }
        }
        .right{
            p{
                color: #fff;
                line-height: 24px;
                font-size: 18px;
                font-weight: 700;
            }
        }
    }
}


// about area

.about-image-v-inner{
    .image-area{
        display: flex;
        position: relative;
        .img-1{
            border-top: 10px solid var(--color-primary);
            padding-top: 10px;
            @media #{$sm-layout} {
                width: 65%;
            }
        }
        .img-over{
            position: absolute;
            // left: 55%;
            right: 0;
            top: 0;
            animation: jump-2 5s linear infinite;
            @media #{$sm-layout} {
                width: 50%;
            }
        }
        .goal-button-wrapper{
            .vedio-icone{
                background: #fff;
                padding: 65px;
                border-radius: 50%;
                @media #{$small-mobile} {
                    padding: 53px;
                }
                .video-play-button{
                    &::after{
                        background: var(--color-primary);
                    }
                    span{
                        border-left: 14px solid #ffffff;
                    }
                }
            }
            position: absolute;
            bottom: -2%;
            right: 29%;     
            @media #{$large-mobile} {
                right: 24%;
            }  
        }
    }
}
.about-progress-inner{
    padding-left: 30px;
    @media #{$md-layout} {
        padding-left: 0;
    }
    @media #{$sm-layout} {
        padding-left: 0;
    }
    .title-area{
        span{
            color: var(--color-primary);
            font-size: 16px;
            letter-spacing: 0.1em;
            font-weight: 600;
        }
        .title{
            margin-top: 5px;
        }
    }
    .inner{
        .rts-progress-one-wrapper{
            padding: 40px;
            background: #F7F7F7;
            @media #{$sm-layout} {
                padding: 25px;
            }
            @media #{$small-mobile} {
                padding: 15px;
            }
            .meter{
                background: #fff;
                height: 15px;
                display: flex;
                align-items: center;
                padding-left: 7px;
                overflow: hidden;
                span{
                    height: 3px;
                }
            }
            .single-progress{
                border-bottom: none;
                .progress-top{
                    p{
                        color: #1C2539;
                    }
                    
                    span{
                        color: var(--color-primary);
                        font-weight: 500;
                        font-size: 16px;
                    }
                }
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        a{
            &.rts-btn{
                display: block;
                max-width: max-content;
                margin-top: 40px;
            }
        }
    }
}


// about-three home

.about-two-bg{
    background-image: url(../images/about/bg-01.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}

// about three area inner

.about-right-three{
    .title-area-about-three{
        span{
            &.sub{
                color: var(--color-primary-3);
                letter-spacing: 0.3em;
                font-weight: 700;
                text-transform: uppercase;
            }
        }
        .title{
            font-size: 60px;
            line-height: 65px;
            text-transform: uppercase;
            margin-top: 20px;
            @media #{$smlg-device} {
                font-size: 44px;
            }
            @media #{$sm-layout} {
                font-size: 36px;
                line-height: 55px;
            }
            @media #{$small-mobile} {
                font-size: 24px;
                line-height: 44px;
            }
            span{
                font-size: 48px;
                @media #{$sm-layout} {
                    font-size: 36px;
                }
                @media #{$sm-layout} {
                    font-size: 24px;
                }
                span{
                    color: var(--color-primary-3);
                }
            }
        }
    }
}
.about-three-thumbnail-left{
    img{
        @media #{$md-layout} {
            width: 100%;
        }
    }
    position: relative;
    &::after{
        position: absolute;
        content: '';
        top: 60px;
        left: 20px;
        width: 200px;
        height: 10px;
        width: 120px;
        background: var(--color-primary-3);
    }
    .about-sm-thumb{
        position: absolute;
        top: 13%;
        left: -28%;
        animation: jump-1 5s linear infinite;
        @media #{$smlg-device} {
            left: -11%; 
            width: 65%;
            top: 14%;
        }
    }
    .vedio-icone{
        position: absolute;
        right: -5%;
        bottom: -6%;
        max-width: max-content;
        padding: 0 !important;
        height: auto;
        object-fit: contain;
        cursor: auto;
        @media #{$small-mobile} {
            right: -8%;
            bottom: -17%;
        }
        span{
            position: relative;
            height: auto;
            width: auto;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &::after{
                display: none;
            }
            &::before{
                display: none;
            }
            img{
                width: 120px;
                height: 143px;
            }
            span{
                position: relative;
                border-left: 25px solid #ffffff;
                border-top: 16px solid transparent;
                border-bottom: 16px solid transparent;
                transform: translate(-50%, -39%);
                left: -58px;
            }
        }
    }

}

.inner-about-three{
    p{
        &.disc{
            padding-left: 20px;
            border-left: 5px solid var(--color-primary-3);
            margin-bottom: 30px;
            margin-top: 0;
            font-size: 16px;
            line-height: 26px;
            font-weight: 400;
        }
    }
}

.solution-wrapper-right{
    margin-left: -60px;
    @media #{$sm-layout} {
        margin-left: -20px;
    }
    @media #{$large-mobile} {
        margin-left: 0;
    }
}

.single-business-solution{  
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    &:last-child{
        margin-bottom: 0;
    }
    i{
        height: 18px;
        width: 18px;
        background: var(--color-primary-3);
        font-size: 10px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color:#FFF;
    }
    p{
        color: #1C2539;
        font-weight: 500;
        font-size: 16px;
        margin-left: 10px;
    }
}

.rts-user-three-wrapper{
    margin-top: 65px;
    display: flex;
    align-items: center;
    @media #{$sm-layout} {
        align-items: inherit;
        flex-direction: column;
    }
    .user-wrapper{
        display: flex;
        align-items: center;
        position: relative;
        flex-basis: 27%;
        a{
            position: absolute;
            left: 0;
            z-index: 2;
            transition: .3s;
            &:nth-child(2){
                margin-left: 50px;
                z-index: 4;
            }
            &:nth-child(3){
                margin-left: 100px;
                z-index: 3;
            }
            &:hover{
                z-index: 10;
                transform: scale(1.05) translateY(-5px);
            }
        }
    }
    .reviews{
        @media #{$sm-layout} {
            margin-top: 50px;
        }
        .title{
            margin-bottom: 0px;
        }
        span{
            color: var(--color-primary-3);
        }
    }
    .rts-btn{
        @media #{$sm-layout} {
            max-width: max-content;
            margin-left: 0 !important;
            margin-top: 20px;
        }
    }
}
// cta three style hear
.cta-three-wrapper{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @media #{$sm-layout} {
        flex-direction: column;
        align-items: flex-start;
    }
    .title{
        margin-bottom: 0;
        color: #fff;
        text-transform: uppercase;
        @media #{$smlg-device} {
            font-size: 20px;
        }
        @media #{$md-layout} {
            font-size: 16px;
        }
        a{
            color: var(--color-primary-3);
        }
    }
    a{
        margin-left: auto;
        @media #{$sm-layout} {
            margin-left: 0;
            margin-top: 20px;
        }
    }
}



.contoler-company{
    display: flex;
    @media #{$sm-layout} {
        flex-direction: column;
    }
    .tab-content{
        @media #{$sm-layout} {
            margin-top: 30px;
        }
    }
}


.about-company-wrapper{
    p{
        &.disc{
            color: #5D666F;
            font-size: 18px;
            margin-bottom: 60px;
            line-height: 28px;
            @media #{$sm-layout} {
                font-size: 16px;
                margin-bottom: 25px;
                br{
                    display: none;
                }
            }
        }
    }
}

.rts-tab-style-one{
    .button-area{
        padding: 30px;
        border: 1px solid #EDEFF3;
        border-radius: 20px;
        background: #fff;
        text-align: left;
        min-width: max-content;
        @media #{$sm-layout} {
            padding: 10px;
        }
        button{
            background: transparent !important;
            color: #5D666F;
            padding-bottom: 20px;
            border-bottom: 1px solid #EDEFF3;
            margin-bottom: 15px;
            font-size: 18px;
            font-weight: 600;
            text-align: left;
            @media #{$sm-layout} {
                font-size: 14px;
            }
            &:last-child{
                border-bottom: none;
                margin-bottom: 0;
                padding-bottom: 0;
            }
            &.active{
                color: #0B4DF5;
            }
        }
    }
    .tab-content{
        margin-left: 30px;
        @media #{$sm-layout} {
            margin-left: 10px;
        }
        .rts-tab-content-one{
            margin-right: -8px;
            p{
                &.disc{
                    font-size: 16px;
                    font-weight: 500;
                    color: #1C2539;
                    line-height: 26px;
                    margin-top: -4px;
                    margin-bottom: 24px;
                }
            }
            .check-area{
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                &:last-child{
                    margin-bottom: 0;
                }
                i{
                    color: var(--color-primary-2);
                    font-size: 18px;
                }
                p{
                    margin-bottom: 0;
                    margin-left: 10px;
                    font-size: 16px;
                    color: #5D666F;
                    font-weight: 400;
                    margin-bottom: -6px;
                }
            }
            a{
                &.rts-btn{
                    margin-top: 40px;
                    display: block;
                    max-width: max-content;
                }
            }
        }
    }
}
.home-blue{
    .title-area{
        &.service-h2{
            .title{
                @media #{$sm-layout} {
                    font-size: 23px;
                    color: #1C2539 !important;
                    font-weight: 700;
                    line-height: 58px;
                    line-height: 30px;
                }
            }
        }
    }
}
.home-blue{
    .title-area{
        .title{
            @media #{$sm-layout} {
                font-size: 23px;
                line-height: 30px;
            }
        }
    }
}

.about-company-thumbnail{
    position: relative;
    z-index: 1;
    &::after{
        position: absolute;
        background-image: url(../images/about/shape/03.png);
        content: "";
        height: 517.43px;
        min-width: 712px;
        background-repeat: no-repeat;
        background-size: contain;
        z-index: -1;
        top: -10%;
        left: 4%;
        animation: jump-1 5s linear infinite;
        @media #{$laptop-device} {
            height: 447.43px;
            min-width: 660px;
        }
        @media #{$smlg-device} {
            height: 380.43px;
            min-width: 712px;
        }
        @media #{$md-layout} {
            height: 96%;
        }
        @media #{$sm-layout} {
            height: 391.43px;
            min-width: 712px;
        }
        @media #{$small-mobile} {
            height: 100%;
            min-width: 708px;
        }
    }
    img{
        margin-left: 40px;
        @media #{$sm-layout} {
            width: 76%;
        }
    }
}

.rts-about-our-company-h2{
    position: relative;
    &::after{
        position: absolute;
        content: '';
        left: 0;
        bottom: 0;
        contain: '';
        height: 235.7px;
        width: 213.54px;
        background-image: url(../images/about/shape/04.png);
        background-repeat: no-repeat;
        animation: jump-2 5s linear infinite;
        z-index: -1;
        @media #{$large-mobile} {
            display: none;
        }
    }
}





