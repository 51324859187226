// call to action style hear


.cta-one-bg {
    background-image: url(../images/cta/01.jpg);
    background-repeat: no-repeat;
    background-color: #DF0A0A;
    background-blend-mode: multiply;
    overflow: hidden;
    background-position: center;
    object-fit: contain;
    background-size: cover;
}

// cta three
.rts-cta-bg {
    background-image: url(../images/cta/03.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}


.cta-one-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 100px 120px;

    @media #{$md-layout} {
        padding: 50px 60px;
    }

    @media #{$sm-layout} {
        padding: 50px 60px;
        flex-direction: column;
    }

    .cta-left {
        width: 50%;

        @media #{$sm-layout} {
            width: 100%;
            margin: 0 auto;
            text-align: center;
        }
    }

    .cta-right {
        @media #{$sm-layout} {
            text-align: center;
            margin-top: 40px;
        }

        .rts-btn {
            @media #{$small-mobile} {
                padding: 13px 20px;
                font-size: 14px;
            }
        }
    }

    .title {
        color: #fff;
        margin-bottom: 0;
        font-size: 40px;

        @media #{$smlg-device} {
            font-size: 24px;
            line-height: 36px;
        }

        @media #{$small-mobile} {
            font-size: 16px;
            line-height: 26px;
            font-weight: 500;
        }
    }
}

// cta for home three
.bg-call-to-action-two {
    background-image: url(../images/cta/02.jpg);
    background-repeat: no-repeat;
    background-color: var(--color-primary-3);
    background-blend-mode: multiply;
    background-position: center;
    object-fit: contain;
    background-size: cover;

}

.cta-two-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 90px 0;

    @media #{$sm-layout} {
        flex-direction: column;
        padding: 45px 0;
    }

    .title-area {
        width: 70%;

        @media #{$sm-layout} {
            width: 100%;
            text-align: center;
            margin-bottom: 25px;
        }

        .title {
            text-transform: uppercase;
            color: #fff;
            margin-bottom: 0;
            font-weight: 700;
            font-size: 40px;
            line-height: 53px;

            @media #{$smlg-device} {
                font-size: 30px;
                line-height: 45px;
            }

            @media #{$sm-layout} {
                font-size: 24px;
                line-height: 36px;
            }
        }
    }
}


.cta-bg-h2 {
    background-image: url(../images/cta/bg-01.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.cta-h2-wrapper {
    display: flex;
    flex-direction: column;
    align-content: center;

    .icon {
        margin-top: 43px;

        a {
            position: relative;
            z-index: 1;
            font-size: 30px;
            color: var(--color-primary-2);

            @media #{$small-mobile} {
                top: 34px;
            }

            &::after {
                position: absolute;
                width: 120px;
                height: 120px;
                content: '';
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                background: #fff;
                border-radius: 50%;
                z-index: -1;

                @media #{$small-mobile} {
                    width: 80px;
                    height: 80px;
                }
            }
        }
    }

    .body {
        margin-top: 85px;
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            &.info {
                font-weight: 400;
                font-size: 22px;
                line-height: 29px;
                color: #fff;
                margin-bottom: 30px;

                @media #{$small-mobile} {
                    margin-bottom: 0;
                }

                span {
                    font-weight: 800;
                }
            }
        }

        a {
            &.number {
                font-size: 72px;
                font-weight: 700;
                color: #fff;
                margin-top: 20px;

                @media #{$large-mobile} {
                    font-size: 41px;
                }
            }
        }

        a {
            &.rts-btn {
                display: block;
                max-width: max-content;
                margin: 50px auto 0 auto;
            }
        }
    }
}

.rts-callto-acation-area4 {
    margin-top: 35px;
    background-color: #000000cc;

    .container {
        .cta-two-wrapper {
            .title {
                text-transform: none;

                @media(max-width:1200px) {
                    br {
                        display: none;
                    }
                }
            }

            .btn-secondary-3 {
                color: var(--color-primary-4);
                background-color: #E4E7FF;

                &:hover {
                    background: var(--color-primary-4);
                    color: #fff;
                }
            }
        }
    }
}