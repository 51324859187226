

.rts-faq-area{
    position:relative;
    overflow: hidden;
    &::after{
        position: absolute;
        content: "";
        right: -9%;
        top: -33%;
        background-image: url(../images/faq/shape.png);
        height: 736px;
        width: 736px;
        background-size: contain;
        z-index: -1;
        animation: rotateIt 20s linear infinite;
        @media #{$md-layout} {
            position: absolute;
            content: "";
            right: -22%;
            top: -16%;
            background-image: url(../images/faq/shape.png);
            height: 500px;
            width: 500px;
            background-size: contain;
            z-index: -1;
            animation: rotateIt 20s linear infinite;
        }
        @media #{$sm-layout} {
            position: absolute;
            content: "";
            right: -22%;
            top: -10%;
            background-image: url(../images/faq/shape.png);
            height: 300px;
            width: 300px;
            background-size: contain;
            z-index: -1;
            animation: rotateIt 20s linear infinite;
        }
    }
    &::before{
        position: absolute;
        content: "";
        left: -14%;
        top: 11%;
        background-image: url(../images/faq/shape-2.png);
        width: 698px;
        height: 698px;
        background-size: contain;
        z-index: -1;
        animation: jump-2 5s linear infinite;
    }
    .faq-thumbnail{
        img{
            width: 100%;
            animation: jump-2 5s linear infinite;
            @media #{$small-mobile} {
                margin-bottom: 30px;
            }
        }
    }
}



// accordion one

.accordion-area{
    .title-area{
        margin-bottom: 50px;
        span{
            color: var(--color-primary);
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 1px;
        }
        .title{
            margin-bottom: 0;
        }
    }
}
.accordion-one-inner{
    .accordion-item{
        box-shadow: 0px 7px 18px rgba(24, 16, 16, 0.05);
        margin-bottom: 20px;
        border: none;
        .accordion-header{
            button{
                font-size: 18px;
                color: #1C2539;
                font-weight: 700;
                padding: 29px 40px;
                @media #{$large-mobile} {
                    padding: 13px 15px;
                    font-size: 14px;
                }
                @media #{$small-mobile} {
                    display: flex;
                    align-items: flex-start;
                }
                &::after{
                    background-image: none;
                    content: '\f078';
                    font-family:"Font Awesome 5 Pro" !important; 
                    transition: .3s;
                    color: var(--color-primary);
                }
                span{
                    color: var(--color-primary);
                    margin-right: 5px;
                    min-width: max-content;
                }
                &:focus{
                    box-shadow: none;
                }
            }
            .accordion-button:not(.collapsed){
                box-shadow: none;
                background: #fff;
                &::after{
                    background-image: none;
                    content: '\f077';
                    font-family:"Font Awesome 5 Pro" !important;
                    transform: rotate(0); 
                    transition: .3s;
                }
            }
        }
        .accordion-body{
            padding: 0px 40px 40px 40px;
            @media #{$small-mobile} {
                font-size: 14px;
                line-height: 24px;
                padding: 20px;
                padding-top: 0;
            }
        }
    }
}

.tab-content>.tab-pane {
    display: none;
    transition: .3s;
}

.tab-content>.active {
    display: block;
    transition: .3s;
}

.tab-content{
    transition: .3s;
}


.rts-faq-bg{
    background-image: url(../images/faq/bg-01.jpg);
}


.faq-two-inner{
    .title-area-faq{
        .sub{
            color: var(--color-primary);
            font-size: 16px;
            font-weight: 700;
            letter-spacing: 0.3em;
        }
        span{
            &.sm-title{
                font-size: 40px;
                @media #{$large-mobile} {
                    font-size: 30px;
                }
                @media #{$small-mobile} {
                    font-size: 20px;
                }
                span{
                    color: var(--color-primary);
                    font-weight: 600;
                }
            }
        }
        .title{
            color:#fff;
            text-transform: uppercase;
            margin-top: 5px;
        }
    }
    .faq-accordion-area{
        margin-top: 47px;
        .accordion{
            .accordion-item{
                background: transparent;
                margin-bottom: 30px;
                &:last-child{
                    margin-bottom: 0;
                }
                .accordion-header{
                    button{
                        border: 1px solid #414857;
                        background: transparent;
                        border-radius: 5px;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 24px;
                        color: #ffffff;
                        height: 60px;
                        align-items: center;
                        display: flex;
                        padding: 0 35px;
                        @media #{$large-mobile} {
                            font-size: 14px;
                        }
                        &[aria-expanded="true"]{
                            border: 1px solid transparent;
                            background: #fff;
                            color: #1C2539;
                            border-radius: 5px 5px 0 0;
                            &::after{
                                content: '\f077';
                                font-family: "Font Awesome 5 Pro" !important;
                                background-image: none;
                                top: -5px;
                                position: relative;
                            }
                        }
                        span{
                            color: var(--color-primary);
                            margin-right: 10px;
                            min-width: max-content;
                        }
                        &:focus{
                            box-shadow: none;
                        }
                        &::after{
                            content: '\f078';
                            font-family: "Font Awesome 5 Pro" !important;
                            background-image: none;
                            top: -5px;
                            position: relative;
                            transform: none;
                            color: var(--color-primary);
                        }
                    }
                    .accordion-button:not(.collapsed) {
                        box-shadow: none;
                    }
                }
            }
            .accordion-body{
                background: #fff;
                border-radius: 0 0 5px 5px;
                padding: 10px 35px 35px 35px;
            }
        }
    }
}

.thumbnail-faq-four{
    padding-left: 30px;
}

.home-yellow{
    .faq-two-inner .title-area-faq .sub,
    .faq-two-inner .title-area-faq span.sm-title span,
    .faq-two-inner .faq-accordion-area .accordion .accordion-item .accordion-header button span,
    .faq-two-inner .faq-accordion-area .accordion .accordion-item .accordion-header button::after{
        color: var(--color-primary-3);
    }
}